i.vigi-neige-verglas {
  content: url('../../images/icon/vigi-neige-verglas.svg');
}

i.vigi-crues {
  content: url('../../images/icon/vigi-crues.svg');
}

i.vigi-pluies-inondations {
  content: url('../../images/icon/vigi-pluies-inondations.svg');
}
i.wait {
  content: url('../../images/icon/wait.svg');
}
i.wait.text-primary {
  content: url('../../images/icon/wait-primary.svg');
}
