@use '../base/typography' as typography;

.modal-content {
  padding: 32px;
  // border: none !important;

  .header {
    text-align: center;
    font-family: typography.$firasans-semi-bold;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 24px;
  }

  .footer {
    margin-top: 16px;
  }

  .btn-cancel-modal {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

.modal-50 .modal-dialog {
  max-width: 50%;
}

.modal-60 .modal-dialog {
  max-width: 60%;
}
